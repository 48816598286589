import { Select } from "@nike/eds";
import styled from "@emotion/styled";

const StyledControlsSelect = styled(Select)`
  padding: 0 0 20px 0;
`;

const ControlsSelect = ({ aspectRatios, onChange, id, value }) => {
  return (
    <StyledControlsSelect
      id={id}
      options={aspectRatios}
      defaultValue={{ value: "Custom", label: "Custom" }}
      label="Aspect ratio"
      onChange={(selected) => onChange(selected)}
      value={value}
    />
  );
};

export default ControlsSelect;
