import styled from "@emotion/styled";
import { Label } from "@nike/eds";

export interface CroppedImageProps {
  croppedImage: DefaultCroppedImageDetails;
  handleCroppedImageClick: (
    croppedImage: DefaultCroppedImageDetails,
    index: number
  ) => void;
  isSelected: boolean;
  index: number;
}

export interface DefaultCroppedImageDetails {
  aspectRatio: string;
  url: string;
  resolution: string;
  name: string;
}

type SmallImageProps = {
  src: string;
  alt?: string;
  isSelected: boolean;
  onClick: () => void;
};

const SmallImage = styled.img<SmallImageProps>`
  object-fit: contain;
  cursor: pointer;
  border-radius: 5px;
  transition: box-shadow 0.2s ease, transform 0.2s ease;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    transform: scale(1.05);
  }

  border: ${(props) =>
    props.isSelected ? "2px solid var(--eds-color-brand-orange)" : "none"};
`;

const StyledImageContainer = styled.div`
  margin-top: 10px; 
  position: relative;
  width: 100%;
  height: 90%;
`;

// const SpinnerContainer = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 10;
// `;

const CroppedImage: React.FC<CroppedImageProps> = ({
  croppedImage,
  handleCroppedImageClick,
  isSelected,
  index,
}) => {
  // const [loading, setLoading] = useState(false);

  return (
    <div>
      <Label font="subtitle-1">
        {croppedImage.name}
      </Label>
      <Label font="body-3">
        Aspect Ratio: {croppedImage.aspectRatio} (w x h)
      </Label>
      {/* <label className="eds-type--body-3">
        Resolution: {croppedImage.resolution}
      </label> */}

      {croppedImage.url && (
        <StyledImageContainer>
          {/* {loading && (
            <SpinnerContainer>
              <Spinner size="large" />
            </SpinnerContainer>
          )} */}
          <SmallImage
            key={index}
            src={croppedImage.url as string}
            alt="Small preview"
            onClick={() => handleCroppedImageClick(croppedImage, index)}
            isSelected={isSelected}
          />
        </StyledImageContainer>
      )}
    </div>
  );
};

export default CroppedImage;
