import { Theme as MuiTheme, createTheme } from '@mui/material/styles';

import constants from './constants';

const customTheme:any  = {
  colors: constants.color,
  appHeaderHeight: '64px',
  text: {
    primary: constants.color.black,
    primaryInverse: constants.color.white,
    secondary: constants.color.grey1,
  },
  background: {
    primary: constants.color.white,
    secondary: constants.color.black,
  },
};

export type CustomTheme = {
  [Key in keyof typeof customTheme]: typeof customTheme[Key];
};

export type Theme = MuiTheme & CustomTheme;

export default createTheme(customTheme);
