import styled from "@emotion/styled";
import CroppedImage from "./CroppedImage";
import { useState } from "react";

interface CroppedImagesContainerProps {
  croppedImages: DefaultCroppedImageDetails[];
  handleCroppedImageClick: (croppedImage: DefaultCroppedImageDetails) => void;
  isContainerExpanded: boolean;
  selectedImageUrl: string;
  children?: React.ReactNode;
}

export interface DefaultCroppedImageDetails {
  aspectRatio: string;
  url: string;
  resolution: string;
  name: string;
}

const ActionContainer = styled("div")`
  height: 100%;
  background: rgb(255, 255, 255);
  padding: 20px;
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  flex-direction: column;
  min-width: fit-content;
  border-left: 1.5px solid var(--eds-color-grey-2);
`;

const PlacementTitle = styled("label")`
  display: block;
  margin-bottom: 10px;
`;

const SmallImageContainer = styled.div<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? "grid" : "flex")};
  grid-template-columns: repeat(3, 1fr);
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  overflow-y: scroll;
  padding: 0px 20px 0px 20px;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  & > div:last-child {
    margin-bottom: 20px;
  }
`;

const CroppedImagesContainer: React.FC<CroppedImagesContainerProps> = ({
  croppedImages,
  handleCroppedImageClick,
  selectedImageUrl,
  isContainerExpanded,
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>();

  const handleOnClickImage = (
    croppedImage: DefaultCroppedImageDetails,
    index: number
  ) => {
    setSelectedImageIndex(index);
    handleCroppedImageClick(croppedImage);
  };

  return (
    <ActionContainer>
      <PlacementTitle className="eds-type--title-3">
        Placement: EMAIL
      </PlacementTitle>
      <SmallImageContainer isExpanded={isContainerExpanded}>
        {croppedImages.map((croppedImage, index) => (
          <CroppedImage
            croppedImage={croppedImage}
            index={index}
            handleCroppedImageClick={handleOnClickImage}
            isSelected={selectedImageIndex === index}
          />
        ))}
      </SmallImageContainer>
    </ActionContainer>
  );
};

export default CroppedImagesContainer;
