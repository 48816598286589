// Imported from https://github.com/nike-enterprise-design-system/eds/blob/main/src/tokens/color.js
const color = {
  black: '#111111',
  grey1: '#757575',
  grey2: '#cccccc',
  grey3: '#e5e5e5',
  grey4: '#f5f5f5',
  grey5: '#fafafa',
  grey6: '#efefef',
  grey7: '#6a6a6a',
  grey1OnDark: '#b2b2b2',
  grey2OnDark: '#8b8b8b',
  grey3OnDark: '#757575',
  grey4OnDark: '#3a3a3a',
  grey5OnDark: '#222222',
  white: '#ffffff',
  red100: '#3D1F17',
  red90: '#4C2318',
  red80: '#6C2B1B',
  red70: '#8B321D',
  red60: '#AD391F',
  red50: '#D43F21',
  red40: '#E06242',
  red30: '#EC8668',
  red20: '#F6A78F',
  red10: '#FBC4B3',
  red05: '#FFEBE4',
  orange100: '#382113',
  orange90: '#472713',
  orange80: '#633212',
  orange70: '#7F3D0E',
  orange60: '#9D4808',
  orange50: '#BB5901',
  orange40: '#D26E01',
  orange30: '#EE8800',
  orange20: '#FFA739',
  orange10: '#FFC682',
  orange05: '#FFECD6',
  yellow100: '#332412',
  yellow90: '#3E2B12',
  yellow80: '#55380F',
  yellow70: '#6F4709',
  yellow60: '#895600',
  yellow50: '#A46700',
  yellow40: '#BC7C00',
  yellow30: '#D59700',
  yellow20: '#EBB102',
  yellow10: '#FACA40',
  yellow05: '#FFEEC2',
  green100: '#1A2B16',
  green90: '#1C3516',
  green80: '#1E4717',
  green70: '#1F5B16',
  green60: '#1C7013',
  green50: '#128A09',
  green40: '#309E26',
  green30: '#4DB843',
  green20: '#67D15D',
  green10: '#9CE190',
  green05: '#DFF5D9',
  teal100: '#1A292C',
  teal90: '#1D3236',
  teal80: '#20434A',
  teal70: '#21575F',
  teal60: '#226B71',
  teal50: '#208183',
  teal40: '#1B9897',
  teal30: '#11B5AF',
  teal20: '#5BCBC6',
  teal10: '#84E0DC',
  teal05: '#CBF7F6',
  blue100: '#1C273C',
  blue90: '#1E2F4C',
  blue80: '#203E6B',
  blue70: '#1B4F90',
  blue60: '#0861B5',
  blue50: '#2477C9',
  blue40: '#3C8DD7',
  blue30: '#51A9E8',
  blue20: '#64C4F9',
  blue10: '#95D9FF',
  blue05: '#DCF2FF',
  indigo100: '#272342',
  indigo90: '#2E2A53',
  indigo80: '#3A3675',
  indigo70: '#48439F',
  indigo60: '#5451C8',
  indigo50: '#6A64DF',
  indigo40: '#837BED',
  indigo30: '#9F95FD',
  indigo20: '#BBB1FF',
  indigo10: '#D2CAFF',
  indigo05: '#F0EDFF',
  purple100: '#312038',
  purple90: '#3D2647',
  purple80: '#542F65',
  purple70: '#6D3986',
  purple60: '#8743A8',
  purple50: '#A34DCD',
  purple40: '#B865E2',
  purple30: '#CA88E9',
  purple20: '#DAA8EF',
  purple10: '#E7C4F5',
  purple05: '#F7EBFC',
  pink100: '#3E1E1E',
  pink90: '#4E2123',
  pink80: '#6C272C',
  pink70: '#8F2B36',
  pink60: '#B32E40',
  pink50: '#DB2F4B',
  pink40: '#FF3959',
  pink30: '#FF777E',
  pink20: '#FFA0A1',
  pink10: '#FFC1C0',
  pink05: '#FFEAE9',
};

const background = {
  success: color.green50,
  danger: color.red50,
  warning: color.yellow10,
  default: color.white,
  hover: color.grey4,
  active: color.grey3,
};

const border = {
  success: color.green40,
  danger: color.red40,
  warning: color.yellow40,
  hover: color.grey1,
  active: color.black,
  focus: color.blue60,
};

const text = {
  success: color.green60,
  danger: color.red60,
  warning: color.yellow60,
  default: color.black,
  secondary: color.grey7,
  link: color.blue60,
  linkDisable: color.grey2,
};

const constants = {
  background,
  border,
  color,
  text,
};

export default constants;
