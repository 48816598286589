import React, { ReactNode, useCallback } from "react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useHistory } from "react-router-dom";

console.log('issuer', process.env.REACT_APP_OKTA_ISSUER)
const appAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER!,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: window.location.origin + "/login/callback",
  scopes: process.env.REACT_APP_OKTA_SCOPES?.split(","),
});

interface AppSecurityProps {
  children: ReactNode;
}

const AppSecurity = ({ children }: AppSecurityProps) => {
  const history = useHistory();


  const restoreOriginalUri = useCallback(
    async (oktaAuth: OktaAuth, originalUri: string) => {
      history.replace(
        toRelativeUrl(originalUri || "/", window.location.origin)
      );
    },
    [history]
  );

  return (
    <Security oktaAuth={appAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default AppSecurity;
