import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { keyframes } from "@emotion/react";
import { CopyPaste } from "@nike/nike-design-system-icons";
import { Button, Label, Snack, Snackbar, Spinner } from "@nike/eds";
import noImage from '../assets/images/noImage.png';

interface ImageContainerProps {
  imageUrl: string;
  showModal: () => void;
  handleCopyUrl: () => void;
  width: number;
  height: number;
  children?: React.ReactNode;
}

const ImageMainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 25px 10px 25px;
  height: 100%;
`;

const fadeIn = keyframes`
  0% {opacity:0;}
  100% {opacity:1;}
`;

const Image = styled.img<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: 0.3s ${fadeIn} ease-in;
  filter: ${(props) => (props.isLoading ? "blur(10px)" : "none")};
`;

const Dimensions = styled.p<{ isLoading: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 12px;
  margin: 0;
  animation: 0.3s ${fadeIn} ease-in;
  filter: ${(props) => (props.isLoading ? "blur(10px)" : "none")};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledAddImageLabel = styled(Label)`
  margin: 10px;
`;

const CustomUrlContainer = styled.div`
  display: flex;
  height: 10%;
  align-items: center;
  animation: 0.5s ${fadeIn} ease-in;
`;

const StyledUrlLabel = styled(Label)`
  width: 80%;
  border: 1.5px solid var(--eds-color-grey-2);
  background: white;
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
`;

const StyledButton = styled(Button)`
  min-width: 150px;
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;
const StyledSnack = styled(Snack)`
  animation: 0.3s ${fadeIn} ease-in;
`;

const ImageContainer: React.FC<ImageContainerProps> = ({
  imageUrl,
  showModal,
  handleCopyUrl,
  width,
  height
}) => {
  const [loading, setLoading] = useState(false);
  const [noImageUrl, setNoImageUrl] = useState('');
  const [showErrorSnack, setShowErrorSnack] = useState(false);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = (e: any) => {
    console.error("Error loading image", e);
    setNoImageUrl(noImage);
    setShowErrorSnack(true)
    setLoading(false);
  };

  useEffect(() => {
    setNoImageUrl('');
    setLoading(true);
  }, [imageUrl]);

  return (
    <ImageMainContainer>
      {imageUrl && (
        <StyledImageContainer>
          {loading && (
            <SpinnerContainer>
              <Spinner size="large" />
            </SpinnerContainer>
          )}
          <Image
            src={noImageUrl || imageUrl}
            alt="User uploaded"
            onLoad={handleImageLoad}
            onError={handleImageError}
            isLoading={loading}
          />          
          <Dimensions isLoading={false}>{`${width}x${height}`}</Dimensions>
        </StyledImageContainer>
      )}

      {!imageUrl && (
        <MessageContainer>
          <StyledAddImageLabel font="title-3">
            Add Your First Image to Begin Editing
          </StyledAddImageLabel>
          <Button onClick={showModal}>Add Image</Button>
        </MessageContainer>
      )}

      {imageUrl && (
        <CustomUrlContainer>
          <StyledUrlLabel>{imageUrl}</StyledUrlLabel>
          <StyledButton beforeSlot={<CopyPaste />} onClick={handleCopyUrl}>
            Copy Url
          </StyledButton>
        </CustomUrlContainer>
      )}
      <Snackbar>
        {showErrorSnack && (
          <StyledSnack
            id="1"
            status="error"
            autoDismissDuration={10000}
            onDismiss={(id) => setShowErrorSnack(false)}
          >
            Error: The image failed to load. Try a different configuration.
          </StyledSnack>
        )}
      </Snackbar>
    </ImageMainContainer>
  );
};

export default ImageContainer;
