class ImageTransformer {
  static getAspectRatioTransform(aspectRatio: string) {
    return `ar_${aspectRatio}`;
  }

  static getQualilyEcoTransform() {
    return "q_auto:eco";
  }

  static getWidthTransform(width: number) {
    return `w_${width}`;
  }

  static getHeightTransform(height: number) {
    return `h_${height}`;
  }

  static getAiSmartCropTransform() {
    return 'g_auto,c_auto';
  }

  static getImageTransformations(transformations: string[]) {
    return transformations
      .filter(Boolean)
      .join(",");
  }

  static buildImageUrl(baseUrl: string, tailUrl: string, transformations: string) {
    let url = `${baseUrl}/${transformations ? transformations : ""}/${tailUrl}`;
    return url.replace(",/", "/");
  }

  static getDefaultCroppedSmallImage(
    baseUrl: string,
    tailUrl: string,
    aspectRatio: string,
  ) {
    const transformations = ImageTransformer.getImageTransformations([
      ImageTransformer.getQualilyEcoTransform(),
      ImageTransformer.getAspectRatioTransform(aspectRatio),
      ImageTransformer.getWidthTransform(350),
      ImageTransformer.getAiSmartCropTransform(),
    ]);
    return ImageTransformer.buildImageUrl(baseUrl, tailUrl, transformations);
  }
}

export default ImageTransformer;