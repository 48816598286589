import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppHeader } from "@nike/eds";
import { NikeApp } from "@nike/nike-design-system-icons";
import styled from "@emotion/styled";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AppSecurity from "./AppSecurity";
import appTheme from "../theme";
import Routes from "./Routes";

const AppContainer = styled("main")`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const AppContent = styled("div")`
  display: flex;
  flex-grow: 1;
  background: #f5f5f5;
  max-height: calc(100vh - 64px);
`;

const App = () => {
  return (
    <Router>
      <AppSecurity>
        <MuiThemeProvider theme={appTheme}>
          <EmotionThemeProvider theme={appTheme}>
            <AppContainer>
              <AppHeader
                appName="D6 AI Editor"
                isDark
                logoSlot={
                  <NikeApp color={"white"} height={"64px"} width={"56px"} />
                }
              />
              <AppContent>
                <Routes />
              </AppContent>
            </AppContainer>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </AppSecurity>
    </Router>
  );
};

export default App;
