import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Button,
  Label,
  Radio,
  RadioGroup,
  Snack,
  Snackbar,
} from "@nike/eds";
import { useEffect, useReducer, useRef, useState } from "react";
import ImageContainer from "../components/ImageContainer";
import AddImageModal from "../components/AddImageModal";
import ControlsSelect from "../components/ControlsSelect";
import reducer, { ActionTypes, initialState } from "./reducer";
import ImageTransformer from "../utils/ImageTransformer";
import CroppedImagesContainer from "../components/CroppedImagesContainer";
import { Icon } from "@nike/eds";

interface DefaultCroppedImage {
  name: string;
  aspectRatio: string;
  resolution: string;
  width: number;
  height: number;
}

const defaultCropImageConfig: DefaultCroppedImage[] = [
  {
    name: "Large (Primary Tout)",
    aspectRatio: "3:4",
    resolution: "1280 x 1707 px",
    width: 1280,
    height: 1707,
  },
  {
    name: "Small Horizontal (Dynamic Module)",
    aspectRatio: "4:3",
    resolution: "670 x 430 px",
    width: 670,
    height: 430,
  },
  {
    name: "XL",
    aspectRatio: "3:5",
    resolution: "1280 x 2069 px",
    width: 1280,
    height: 2069,
  },
  {
    name: "Campaign P1",
    aspectRatio: "3:4",
    resolution: "1707 x 2069 px",
    width: 1707,
    height: 2069,
  },
  {
    name: "Campaign P2 - A",
    aspectRatio: "3:4",
    resolution: "670 x 893 px",
    width: 670,
    height: 893,
  },
  {
    name: "Campaign P2 - B",
    aspectRatio: "1:1",
    resolution: "1143 x 1143 px",
    width: 1143,
    height: 1143,
  },
  {
    name: "Newsletter",
    aspectRatio: "4:3",
    resolution: "670 x 450 px",
    width: 670,
    height: 450,
  },
  {
    name: "Test 1",
    aspectRatio: "4:5",
    resolution: "2000 x 2500 px",
    width: 2000,
    height: 2500,
  },
  {
    name: "Test 2",
    aspectRatio: "9:16",
    resolution: "1080 x 1920 px",
    width: 2000,
    height: 2500,
  },
];

export interface DefaultCroppedImageDetails {
  aspectRatio: string;
  url: string;
  resolution: string;
  name: string;
}

const aspectRatios = [
  { value: "Custom", label: "Custom" },
  { value: "4:5", label: "4:5" },
  { value: "3:4", label: "3:4" },
  { value: "9:16", label: "9:16" },
  { value: "16:10", label: "16:10" },
  { value: "5:2", label: "5:2" },
  { value: "1:1", label: "1:1" },
];

const ControlsContainer = styled("div")`
  background: rgb(255, 255, 255);
  padding: 20px;
  min-width: 15%;
  flex-basis: calc(15%);
  justify-content: flex-start;
  border-right: 1.5px solid var(--eds-color-grey-2);
`;

const ControlHeader = styled("label")`
  display: block;
  margin-bottom: 10px;
  word-break: break-all;
`;

const fadeIn = keyframes`
  0% {opacity:0;}
  100% {opacity:1;}
`;

const ImagesContainer = styled("div")`
  width: 65%;
  min-width: 40%;
`;

const StyledSnack = styled(Snack)`
  animation: 0.3s ${fadeIn} ease-in;
`;

const AddImageButton = styled(Button)`
  margin: 20px;`;

const MessageContainer = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledAddImageLabel = styled(Label)`
  margin: 10px;
`;

const CroppedImagesContainerWrapper = styled.div<{ isExpanded: boolean }>`
  align-items: center;
  display: flex;
  position: relative;
  width: ${({ isExpanded }) => (isExpanded ? "60%" : "20%")};
  transition: width 0.6s ease;
`;

const ExpandButton = styled.button`
  height: 30px;
  padding-top: 2px;
  position: fixed;
  border: 1.5px solid var(--eds-color-grey-2);
  margin: -22px;
  z-index: 1;
  border-radius: 5px;
  transition: transform 0.3s linear;
  color: var(--eds-color-white);
  background: var(--eds-color-text-default);

  &:hover {
    transform: scale(1.2);
  }
`;

const Main = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [imageUrlsArray, setImageUrlsArray] = useState<string[]>([]);
  const [croppedImages, setCroppedImages] = useState<
    DefaultCroppedImageDetails[]
  >([]);
  const [inputTextValue, setInputTextValue] = useState("");
  const [showSnack, setShowSnack] = useState(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    setCroppedImages(
      defaultCropImageConfig.map((config) => {
        return {
          aspectRatio: config.aspectRatio,
          name: config.name,
          resolution: `${config.width} x ${config.height} px`,
          url: ImageTransformer.getDefaultCroppedSmallImage(
            state.baseUrl,
            state.tailUrl,
            config.aspectRatio
          ),
        };
      })
    );
  }, [state.baseUrl, state.tailUrl]);

  enum TransformEffects {
    None = "",
    Crop = "c_crop",
    AICrop = "g_auto,c_auto",
    AIGenFill = "b_gen_fill",
  }

  useEffect(() => {
    if (!state.baseUrl || !state.tailUrl) return;

    const aiTransform = state.effect && state.aspectRatio ? state.effect : "";
    const cropPaddingTransform = state.cropPadding ? "c_pad,b_white" : "";

    const transformations = ImageTransformer.getImageTransformations([
      "q_auto:eco",
      state.aspectRatio,
      cropPaddingTransform,
      aiTransform,
    ]);

    const url = ImageTransformer.buildImageUrl(
      state.baseUrl,
      state.tailUrl,
      transformations
    );
    setImageUrl(url);
  }, [
    state.effect,
    state.aspectRatio,
    state.baseUrl,
    state.height,
    state.tailUrl,
    state.width,
    state.arHeight,
    state.arWidth,
    state.cropPadding,
  ]);

  const handleSetEffect = async (e: any) => {
    dispatch({ type: ActionTypes.UPDATE_EFFECT, value: e });
    dispatch({
      type: ActionTypes.UPDATE_CROP_PADDING,
      value:
        e === TransformEffects.AICrop || e === TransformEffects.Crop
          ? false
          : true,
    });
  };

  const handleAspectRatioChange = async (e: any) => {
    dispatch({ type: ActionTypes.UPDATE_ASPECT_RATIO, value: e.value });
    dispatch({
      type: ActionTypes.UPDATE_CROP_PADDING,
      value: e.value === "Custom" ? false : true,
    });
    dispatch({
      type: ActionTypes.UPDATE_CROP_PADDING,
      value: e.value === "Custom" ? false : true,
    });
  };

  const showModal = () => {
    setInputTextValue("");
    setIsModalVisible(true);
    triggerExpandButtonClick(false);
  };

  const handleCopyUrl = () => {
    copyToClipboard(imageUrl);
  };

  const handleAddImage = (imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      dispatch({
        type: ActionTypes.ADD_IMAGE_URL,
        value: { url: imageUrl, width: img.width, height: img.height },
      });
      triggerExpandButtonClick(true);
    };

    img.onerror = (err) => {
      console.error(err);
    };

    setImageUrl(imageUrl);
    if (!imageUrlsArray.includes(imageUrl)) {
      setImageUrlsArray([...imageUrlsArray, imageUrl]);
    }

    setIsModalVisible(false);
    setSelectedImageUrl(imageUrl);
  };

  const handleCroppedImageClick = (
    croppedImage: DefaultCroppedImageDetails
  ) => {
    const { aspectRatio } = croppedImage;
    setIsExpanded(false);
    dispatch({ type: ActionTypes.UPDATE_ASPECT_RATIO, value: aspectRatio });
    dispatch({
      type: ActionTypes.UPDATE_EFFECT,
      value: TransformEffects.AICrop,
    });
  };

  const handleInputChange = (e: any) => {
    setInputTextValue(e.target.value);
  };

  const copyToClipboard = async (data: string) => {
    try {
      await navigator.clipboard.writeText(data);
      setShowSnack(true);
    } catch (err) {
      console.log("Failed to copy: ", err);
    }
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const expandButtonRef = useRef<HTMLButtonElement>(null);

  const triggerExpandButtonClick = (value: boolean) => {
    if (expandButtonRef.current) {
      expandButtonRef.current.click();
    }
    setIsExpanded(value);
  };

  return (
    <>
      {imageUrl && (
        <ControlsContainer>
          <>
            <ControlHeader className="eds-label eds-type--title-4">
              Resize & Crop
            </ControlHeader>
            <ControlsSelect
              id="aspectRatio"
              onChange={handleAspectRatioChange}
              aspectRatios={aspectRatios}
              value={
                state.aspectRatio
                  ? {
                      value: state.aspectRatio,
                      label: state.aspectRatio.split("ar_")[1],
                    }
                  : aspectRatios[0]
              }
            />
            <RadioGroup
              id="unique-id"
              label="Effects"
              name="radio-button-group"
              valueSelected={state.effect}
              onChange={(e) => handleSetEffect(e.target.value)}
            >
              <Radio label="None" value={TransformEffects.None} id="" />
              <Radio
                label="Crop"
                value={TransformEffects.Crop}
                id="smartCrop"
              />
              <Radio
                label="AI Smart Crop"
                value={TransformEffects.AICrop}
                id="smartCrop"
              />
              <Radio
                label="AI Generative Fill"
                value={TransformEffects.AIGenFill}
                id="generativeFill"
                disabled={
                  state.aspectRatio === "Custom" || state.aspectRatio === ""
                }
              />
            </RadioGroup>
            <AddImageButton size="small" onClick={showModal}>
                Change Image
              </AddImageButton>
          </>
        </ControlsContainer>
      )}

      {imageUrl && (
        <ImagesContainer>
          <ImageContainer
            imageUrl={imageUrl}
            showModal={showModal}
            handleCopyUrl={handleCopyUrl}
            width={state.arWidth || state.width}
            height={state.arHeight || state.height}
          />
        </ImagesContainer>
      )}
      {imageUrl && (
        <CroppedImagesContainerWrapper
          isExpanded={isExpanded}
          className="var(--eds-elevation-shadow-1)"
        >
          <ExpandButton onClick={handleExpandClick} ref={expandButtonRef}>
            {isExpanded ? (
              <Icon name="CaretRight" />
            ) : (
              <Icon name="CaretLeft" />
            )}
          </ExpandButton>
          <CroppedImagesContainer
            croppedImages={croppedImages}
            handleCroppedImageClick={handleCroppedImageClick}
            selectedImageUrl={selectedImageUrl}
            isContainerExpanded={isExpanded}
          />
        </CroppedImagesContainerWrapper>
      )}
      <AddImageModal
        isModalVisible={isModalVisible}
        inputTextValue={inputTextValue}
        handleAddImage={handleAddImage}
        handleInputChange={handleInputChange}
        setIsModalVisible={setIsModalVisible}
        imagesUrlArray={imageUrlsArray}
        selectedImageUrl={selectedImageUrl}
      />              

      {!imageUrl && (
        <MessageContainer>
          <StyledAddImageLabel font="title-3">
            Add Your First Image to Begin Editing
          </StyledAddImageLabel>
          <Button onClick={showModal}>Add Image</Button>
        </MessageContainer>
      )}

      <Snackbar>
        {showSnack && (
          <StyledSnack
            id="1"
            status="success"
            autoDismissDuration={3000}
            onDismiss={(id) => setShowSnack(false)}
          >
            URL copied to clipboard
          </StyledSnack>
        )}
      </Snackbar>
    </>
  );
};
export default Main;
