import styled from "@emotion/styled";
import { useState, useRef } from "react";
import { keyframes } from "@emotion/react";
import { Button, ButtonGroup, Modal, Text, TextField } from "@nike/eds";


const SmallImageContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  width: 85%;
  height: 100px;
  overflow-y: scroll;
  padding: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  margin-top: 10px;
`;

type SmallImageProps = {
  src: string;
  alt?: string;
  isSelected: boolean;
  onClick: () => void;
};

const ModalLabel = styled(Text)`
  margin-top: 20px;
`;

const SmallImage = styled.img<SmallImageProps>`
  width: 150px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 5px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  }

  border: ${(props) =>
    props.isSelected ? "2px solid var(--eds-color-brand-orange)" : "none"};
`;

interface AddImageModalProps {
  isModalVisible: boolean;
  inputTextValue: string;
  setIsModalVisible: (isModalVisible: boolean) => void;
  handleAddImage: (imageUrl: string) => void;
  handleInputChange: (e: any) => void;
  imagesUrlArray: string[];
  selectedImageUrl: string;
  children?: React.ReactNode;
}

const fadeIn = keyframes`
  0% {opacity:0;}
  100% {opacity:1;}
`;

const StyledModal = styled(Modal)`
  animation: 0.2s ${fadeIn} ease-in;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: center;
`;

const ModalContentContainer = styled.div`
  min-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AddImageModal: React.FC<AddImageModalProps> = ({
  isModalVisible,
  handleAddImage,
  setIsModalVisible,
  imagesUrlArray,
  selectedImageUrl,
  children,
}) => {
  console.log(children)
  const [inputTextValue, setInputTextValue] = useState("");
  const [isInvalidURL, setIsInvalidURL] = useState(false);

  const handleInputChange = (e: any) => {
    setInputTextValue(e.target.value);
  };
  
  const handleCloseModal = () => {
    setInputTextValue("");
    setIsModalVisible(!isModalVisible);
  };

  const handleAddImageUrl = () => {
    if (isValidUrl(inputTextValue)) {
      setInputTextValue("");
      handleAddImage(inputTextValue);
      setIsModalVisible(false);
    } else {
      setIsInvalidURL(true);
    }
  };
  
  const isValidUrl = (url: string) => {
    try {
      new URL(url);
    } catch (_) {
      return false;
    }
    return true;
  };

  const imageContainerRef = useRef<HTMLDivElement>(null);

  return (
    <StyledModal
      onDismiss={() => handleCloseModal()}
      isOpen={isModalVisible}
      headerSlot={imagesUrlArray.length > 0 ? "Upload or Select Image" : "Upload Image"}  
      footerSlot={
        <StyledButtonGroup>
          <Button onClick={handleAddImageUrl} size="medium">
            Add Image
          </Button>
          <Button
            onClick={() => handleCloseModal()}
            size="medium"
            variant="secondary"
          >
            Cancel
          </Button>
        </StyledButtonGroup>
      }
    >
      <ModalContentContainer>
        <Text font={"body-1"} as={"p"}>
          Public URL to upload:
        </Text>
        <TextField
          id="test"
          value={inputTextValue}
          onChange={handleInputChange}
          errorMessage="Please enter a valid URL"
          label=""
          subtitle=""
          hasErrors={isInvalidURL}
          placeholder="http://remote.site.example/images/remote-image.jpg"
        />
        {imagesUrlArray.length > 0 && (
          <>
            <ModalLabel font={"body-1"} as={"p"}>
              Or select a previous image:
            </ModalLabel>
            <SmallImageContainer ref={imageContainerRef}>
              {imagesUrlArray.map((url, index) => (
                <SmallImage
                  key={index}
                  src={url}
                  alt="Small preview"
                  onClick={() => handleAddImage(url)}
                  isSelected={url === selectedImageUrl}
                />
              ))}
            </SmallImageContainer>
        </>
        )}
      </ModalContentContainer>
    </StyledModal>
  );
};

export default AddImageModal;
