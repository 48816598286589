export interface UrlState {
  baseUrl: string;
  tailUrl: string;
  aspectRatio: string;
  width: number;
  height: number;
  arWidth: number;
  arHeight: number;
  cropPadding: boolean;
  effect: string;
}

export const initialState: UrlState = {
  baseUrl: "",
  tailUrl: "",
  aspectRatio: "Custom",
  width: 0,
  height: 0,
  arWidth: 0,
  arHeight: 0,
  cropPadding: false,
  effect: "",
};

export enum ActionTypes {
  ADD_IMAGE_URL = "ADD_IMAGE_URL",
  UPDATE_ASPECT_RATIO = "UPDATE_ASPECT_RATIO",
  UPDATE_WIDTH = "UPDATE_WIDTH",
  UPDATE_HEIGHT = "UPDATE_HEIGHT",
  UPDATE_CROP_PADDING = "UPDATE_CROP_PADDING",
  UPDATE_EFFECT = "UPDATE_EFFECT",
}

interface ActionTypeToValue {
  [ActionTypes.ADD_IMAGE_URL]: { url: string; width: number; height: number };
  [ActionTypes.UPDATE_ASPECT_RATIO]: string;
  [ActionTypes.UPDATE_WIDTH]: number;
  [ActionTypes.UPDATE_HEIGHT]: number;
  [ActionTypes.UPDATE_CROP_PADDING]: boolean;
  [ActionTypes.UPDATE_EFFECT]: string;
}

interface UpdateAction<K extends keyof ActionTypeToValue> {
  type: K;
  value: ActionTypeToValue[K];
}

export type UpdateUrlAction =
  | UpdateAction<ActionTypes.ADD_IMAGE_URL>
  | UpdateAction<ActionTypes.UPDATE_ASPECT_RATIO>
  | UpdateAction<ActionTypes.UPDATE_WIDTH>
  | UpdateAction<ActionTypes.UPDATE_HEIGHT>
  | UpdateAction<ActionTypes.UPDATE_CROP_PADDING>
  | UpdateAction<ActionTypes.UPDATE_EFFECT>;

export default function reducer(
  state: UrlState,
  action: UpdateUrlAction
): UrlState {
  switch (action.type) {
    case ActionTypes.ADD_IMAGE_URL: {

      const baseUrl = action.value.url.split("/", 5).join("/");
      const tailUrl = action.value.url.split("/").slice(-2).join("/");

      return {
        ...state,
        baseUrl: baseUrl,
        tailUrl: tailUrl,
        aspectRatio: "",
        width: action.value.width,
        height: action.value.height,
        cropPadding: false,
      };
    }
    case ActionTypes.UPDATE_ASPECT_RATIO: {
      const [aspectWidth, aspectHeight] = action.value.split(":");
      const ratio = Number(aspectWidth) / Number(aspectHeight);
      const newWidth = Math.round(state.height * ratio);
      const newHeight = Math.round(state.width / ratio);

      return {
        ...state,
        arWidth: newWidth,
        arHeight: newHeight,
        aspectRatio: action.value === "Custom" || action.value === ""
        ? ""
        : `ar_${action.value}`
      };
    }
    case ActionTypes.UPDATE_CROP_PADDING: {
      return {
        ...state,
        cropPadding: action.value,
      };
    }
    case ActionTypes.UPDATE_EFFECT: {
      const newEffect = state.aspectRatio === "Custom" ? "" : action.value;
      return {
        ...state,
        effect: newEffect,
      };
    }
    default:
      return state;
  }
}
