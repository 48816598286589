import { Switch, Route } from "react-router-dom";
import { LoginCallback, SecureRoute } from '@okta/okta-react';

import { AppRoutePath } from "../App/AppRoutePath";
import Main from "../pages/Main";
import Page404 from '../pages/Page404';

const AppRoutes = () => (
  <Switch>
      <Route path={AppRoutePath.callback} component={LoginCallback} />
      <SecureRoute path={AppRoutePath.home} component={Main} />
      <SecureRoute component={Page404} />
  </Switch>
);

export default AppRoutes;
